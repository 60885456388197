<template>
  <div class="contact-us-page">
    <v-container class="fill-height d-flex justify-center align-center">
      <v-card outlined class="radius-10 pa-7 pa-md-16 contact-us-main-card">
        <v-img contain src="/media/svg/ic-chat_45.svg" height="72" />
        <div class="display-1 font-weight-bold text-center mt-6 mb-9 mx-auto">
          We love to chat
        </div>
        <div>
          <v-form @submit.prevent="send">
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  label="Your Name"
                  v-model="form.name"
                  placeholder="James Bond"
                  counter="30"
                  v-validate="'required|min:3|max:30'"
                  v-bind="veeValidate('Name', 'Name')"
                  filled
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  label="Email Address"
                  v-model="form.email"
                  placeholder="james@bond.com"
                  v-validate="'required|email'"
                  v-bind="veeValidate('Email', 'Email')"
                  filled
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Subject"
                  v-model="form.subject"
                  placeholder="What do you want to discuss about?"
                  counter="60"
                  v-validate="'required|min:3|max:60'"
                  v-bind="veeValidate('Subject', 'Subject')"
                  filled
                />
              </v-col>
              <v-col cols="12">
                <v-textarea
                  v-model="form.message"
                  label="Description"
                  counter="600"
                  v-validate="'max:600|required'"
                  v-bind="veeValidate('Description', 'Description')"
                  placeholder="How can we help you?"
                  filled
                  auto-grow
                />
              </v-col>
            </v-row>
            <v-btn
              type="submit"
              block
              :disabled="
                busy ||
                  errors.any() ||
                  !form.name ||
                  !form.email ||
                  !form.subject ||
                  !form.message
              "
              :loading="busy"
              class="primary main wide large mx-auto"
            >
              send
            </v-btn>
          </v-form>
        </div>
      </v-card>
    </v-container>
    <vue-snackbar />
  </div>
</template>

<script>
import { SEND_MESSAGE } from "@/store/users/contact.module.js";
import VueSnackbar from "@/view/components/Common/VueSnackbar";
import veeValidate from "@/mixins/veeValidate";
export default {
  mixins: [veeValidate],
  components: { VueSnackbar },
  data() {
    return {
      busy: false,
      form: {
        name: "",
        email: "",
        subject: "",
        message: ""
      }
    };
  },
  methods: {
    send() {
      if (this.errors.any() || this.busy) {
        return;
      }
      this.busy = true;
      this.$store
        .dispatch(SEND_MESSAGE, this.form)
        .then(data => {
          this.notifyUserMessage(data.message);
          this.busy = false;
        })
        .catch(error => {
          this.busy = false;
          this.notifyErrorMessage(error);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~vuetify/src/styles/settings/_variables";
.contact-us-page {
  background: rgba(57, 107, 219, 0.06);
  min-height: 100vh;
  .contact-us-main-card {
    @media #{map-get($display-breakpoints, 'md-only')} {
      width: 80%;
    }
    @media #{map-get($display-breakpoints, 'lg-only')} {
      width: 65%;
    }
    @media #{map-get($display-breakpoints, 'xl-only')} {
      width: 60%;
    }
  }
}
</style>
